import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgMotor from '../svg/SvgMotor';
import SvgAnalog from '../svg/SvgAnalog';
import SvgValve from '../svg/SvgValve';

import AnalogModal from '../modal/AnalogModal'
import MotorModal from '../modal/MotorModal'
import ValveModal from '../modal/ValveModal'
import PIDModal from '../modal/PIDModal'
import AquasensorNH3Modal from '../modal/AquasensorNH3Modal'
import AquasenseVerificationModal from '../modal/AquasenseVerificationModal'

export default class AquasenseNH3WellBoat extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        data:{},
        showAnalogModal:false,
        analogModalDataPath:"",
        showMotorModal:false,
        showValveModal:false,
        showPIDModal:false,
        showAquasensorNH3Modal:false,
        showAquasenseVerificationModal:false,
        motorModalDataPath:"",
        valveModalDataPath:"",
        pidModalDataPath:"",
        aquasensorNH3ModalDataPath:"",
        aquasenseVerificationModalDataPath:"",
        motorModalCustomProps:{},
        analogModalCustomProps:{},
        valveModalCustomProps:{},
        pidModalCustomProps:{},
        aquasensorNH3ModalCustomProps:{},
        aquasenseVerificationModalCustomProps:{}
      }
    }

  jsonGet(jsondata, key){
      try{
        let data = jsondata[key];
        if (typeof data == "object"){
              return data; // JSON
        } else {
              return {} //Not Json
        }
      }catch(error){
          return {};   
      }
  }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }

  convertSecondsToHHMMSS = (totalSeconds) => {
    let retValue = "";
    if(!isNaN(totalSeconds)){
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;
      if(hours > 0){
        if(hours < 10){
          retValue = "0" + hours + " h "
        }
        else{
          retValue = hours + " h "
        }
      }
      if(minutes > 0){
        if(minutes < 10){
          retValue += "0" + minutes + " m "
        }
        else{
          retValue += minutes + " m "
        }
      }
      if(seconds > 0){
        if(seconds < 10){
          retValue += "0" + seconds + " s "
        }
        else{
          retValue += seconds + " s "
        }
      }
    }
    return retValue
  }

  openAnalogModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAnalogModal:true, analogModalDataPath:dataPath, analogModalCustomProps:customProps});
      }
    }
  }

  closeAnalogModal = () => {
    this.setState({showAnalogModal:false});
  }

  openMotorModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showMotorModal:true, motorModalDataPath:dataPath, motorModalCustomProps: customProps});
      }
    }
  }

  closeMotorModal = () => {
    this.setState({showMotorModal:false});
  }

  openAquasenseVerificationModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAquasenseVerificationModal:true, aquasenseVerificationModalDataPath:dataPath, aquasenseVerificationModalCustomProps: customProps});
      }
    }
  }

  closeAquasenseVerificationModal = () => {
    this.setState({showAquasenseVerificationModal:false});
  }

  openPIDModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showPIDModal:true, pidModalDataPath:dataPath, pidModalCustomProps: customProps});
      }
    }
  }

  closePIDModal = () => {
    this.setState({showPIDModal:false});
  }

  openValveModal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showValveModal:true, valveModalDataPath:dataPath, valveModalCustomProps: customProps});
      }
    }
  }

  closeValveModal = () => {
    this.setState({showValveModal:false});
  }

  openAquasensorNH3Modal = (dataPath, customProps) => {
    if(this.props.userRole !== undefined){
      if(this.props.userRole.toLowerCase() !== "viewer"){
        this.setState({showAquasensorNH3Modal:true, aquasensorNH3ModalDataPath:dataPath, aquasensorNH3ModalCustomProps: customProps});
      }
    }
  }

  closeAquasensorNH3Modal = () => {
    this.setState({showAquasensorNH3Modal:false});
  }

  render() {
    let data = {};
    let systemCommunicationError = false;
    if(this.props.systemCommunicationError !== undefined){
      systemCommunicationError = this.props.systemCommunicationError;
    }
    if(!systemCommunicationError){
      try{
        data = this.props.data;
      }
      catch(error){
        // pass
      }
    }

    let locationName = this.jsonGet(data,this.props.unitName + "/system/location_name")
    if(locationName.hasOwnProperty("value")){
      locationName = locationName.value;
    }
    else{
      locationName = "";
    }

    let versionNumber = this.jsonGet(data,this.props.unitName + "/system/version")
    if(versionNumber.hasOwnProperty("value")){
      versionNumber = versionNumber.value;
    }
    else{
      versionNumber = "";
    }

    let watchDogTimer = this.jsonGet(data,this.props.unitName + "/system/watchdog")
    if(watchDogTimer.hasOwnProperty("value")){
      watchDogTimer = " PLC watchdog: " + watchDogTimer.value;
    }
    else{
      watchDogTimer = "";
    }
    let externalWaterPumpP3Running = this.jsonGet(data,this.props.unitName + "/motor/p3/external_running/status");
    if(externalWaterPumpP3Running.hasOwnProperty("value")){
      externalWaterPumpP3Running = externalWaterPumpP3Running.value;
    }
    else{
      externalWaterPumpP3Running = false;
    }

    let waterPumpP3StatusRegister = this.jsonGet(data,this.props.unitName + "/motor/p3/status_register");
    if(waterPumpP3StatusRegister.hasOwnProperty("value")){
      waterPumpP3StatusRegister = waterPumpP3StatusRegister.value;
    }

    let airPumpP1StatusRegister = this.jsonGet(data,this.props.unitName + "/motor/p1/status_register");
    if(airPumpP1StatusRegister.hasOwnProperty("value")){
      airPumpP1StatusRegister = airPumpP1StatusRegister.value;
    }
    else{
      airPumpP1StatusRegister = 0;
    }

    let chemicalPumpP6StatusRegister = this.jsonGet(data,this.props.unitName + "/motor/p6/status_register");
    if(chemicalPumpP6StatusRegister.hasOwnProperty("value")){
      chemicalPumpP6StatusRegister = chemicalPumpP6StatusRegister.value;
    }
    else{
      chemicalPumpP6StatusRegister = 0;
    } 

    let motorValve1StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv1/status_register");
    if(motorValve1StatusRegister.hasOwnProperty("value")){
      motorValve1StatusRegister = motorValve1StatusRegister.value;
    }
    else{
      motorValve1StatusRegister = 0;
    }
    let motorValve2StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv2/status_register");
    if(motorValve2StatusRegister.hasOwnProperty("value")){
      motorValve2StatusRegister = motorValve2StatusRegister.value;
    }
    else{
      motorValve2StatusRegister = 0;
    }
    let motorValve3StatusRegister = this.jsonGet(data,this.props.unitName + "/valve/mv3/status_register");
    if(motorValve3StatusRegister.hasOwnProperty("value")){
      motorValve3StatusRegister = motorValve3StatusRegister.value;
    }
    else{
      motorValve3StatusRegister = 0;
    }

    let aquasensor1ErrorCode = this.jsonGet(data,this.props.unitName + "/aquasensor/aquasensor1/error_code");
    if(aquasensor1ErrorCode.hasOwnProperty("value")){
      aquasensor1ErrorCode = aquasensor1ErrorCode.value;
    }
    else{
      aquasensor1ErrorCode = 0;
    }

    let verificationCountDownText = <></>;
    let verificationRemainingTime = this.jsonGet(data,this.props.unitName + "/verification/remaining_time");
    if(verificationRemainingTime.hasOwnProperty("value")){
      verificationRemainingTime = verificationRemainingTime.value;
    }
    else{
      verificationRemainingTime = 0;
    }

    let ozoneWashModeText = <></>;
    let ozoneWashModeActive = this.jsonGet(data,this.props.unitName + "/system/ozone_wash_mode/status");
    if(ozoneWashModeActive.hasOwnProperty("value")){
      ozoneWashModeActive = ozoneWashModeActive.value;
    }
    else{
      ozoneWashModeActive = false;
    }

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() ==="light" ? "black" : "rgb(200,200,200)";
    let pipeIdleColor = this.props.colorTheme() === "light" ? "rgb(213,213,213)" : "#323232";
    let strokeColor = this.props.colorTheme() === "light" ? "rgb(0,0,0)" : "rgb(200,200,200)";

    let communcationErrorText = <></>;
    if(systemCommunicationError){
      communcationErrorText = <text x="960" y="150" fill={textColor} style={{fontSize:"50",alignmentBaseline:"middle",textAnchor:"middle"}} >COMMUNICATION ERROR
                                <animate attributeName="fill" values="rgb(213,0,0);rgb(0,0,0);rgb(213,0,0)" dur="1s" repeatCount="indefinite" />
                              </text>
          
    }
    else if(verificationRemainingTime > 0){
      // Display verification count down text if not com. error is active
      verificationCountDownText = <text x="960" y="150" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{"Verification in progress: " + this.convertSecondsToHHMMSS(verificationRemainingTime)}</text>
    }
    else if(ozoneWashModeActive){
      ozoneWashModeText = <text x="960" y="150" fill={textColor} style={{fontSize:"50",alignmentBaseline:"middle",textAnchor:"middle"}} >Ozone wash mode</text>
    }

    let motorValve1Open = (motorValve1StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let motorValve1Closed = (motorValve1StatusRegister & Math.pow(2,1)) === Math.pow(2,1);
    let motorValve2Open = (motorValve2StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let motorValve2Closed = (motorValve2StatusRegister & Math.pow(2,1)) === Math.pow(2,1);
    let airPumpP1Running = (airPumpP1StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let chemicalPumpP6Running = (chemicalPumpP6StatusRegister & Math.pow(2,0)) === Math.pow(2,0);
    let waterPumpP3Running = (waterPumpP3StatusRegister & Math.pow(2,0)) === Math.pow(2,0); 



    return(

      <div style={{width:"100%",height:"100%",backgroundColor:backgroundColor}}>
        <AnalogModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} unitName={this.props.unitName} customProps={this.state.analogModalCustomProps} show={this.state.showAnalogModal} close={this.closeAnalogModal} dataPath={this.state.analogModalDataPath} data={data}/>
        <MotorModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} unitName={this.props.unitName} customProps={this.state.motorModalCustomProps} show={this.state.showMotorModal} close={this.closeMotorModal} dataPath={this.state.motorModalDataPath} data={data}/>
        <ValveModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} unitName={this.props.unitName} customProps={this.state.valveModalCustomProps} show={this.state.showValveModal} close={this.closeValveModal} dataPath={this.state.valveModalDataPath} data={data}/>
        <PIDModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} unitName={this.props.unitName} customProps={this.state.pidModalCustomProps} show={this.state.showPIDModal} close={this.closePIDModal} dataPath={this.state.pidModalDataPath} data={data}/>
        <AquasensorNH3Modal colorTheme={this.props.colorTheme} unitName={this.props.unitName} customProps={this.state.aquasensorNH3ModalCustomProps} show={this.state.showAquasensorNH3Modal} close={this.closeAquasensorNH3Modal} dataPath={this.state.aquasensorNH3ModalDataPath} data={data}/>
        <AquasenseVerificationModal colorTheme={this.props.colorTheme} auth_token={this.props.auth_token} unitName={this.props.unitName} customProps={this.state.aquasenseVerificationModalCustomProps} show={this.state.showAquasenseVerificationModal} close={this.closeAquasenseVerificationModal} dataPath={this.state.aquasenseVerificationModalDataPath} data={data}/>


        <div style={{width:"100%", height:"100%"}}>
         <svg style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">

          <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{this.props.unitName.toUpperCase()}</text>
          <text x="960" y="100" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >{locationName}</text>
          <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>
          <text x="20" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"start",textAnchor:"start"}} >{watchDogTimer}</text>
          <text x="20" y="130" fill={textColor} style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >{versionNumber}</text>
          {communcationErrorText}
          {verificationCountDownText}
          {ozoneWashModeText}

          {/* Air pipe to from equilibrator to motor valve 2 */}
          <polyline fill={(motorValve2Closed && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="990,500 990,460 1125,460 1125,415 1115,415 1115,450 980,450 980,500" /> 

          {/* Air pipe from motor valve 2 to outdoor air */}
          <polyline fill={(motorValve2Open && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1085,375 1055,375 1055,385 1085,385" /> 
          <text x="1050" y="385" fill={textColor} style={{fontSize:"16",alignmentBaseline:"end",textAnchor:"end"}} >Exhaust air</text>
          
          {/* Air pipe from motor valve 2 to air pump P1 */}
          <polyline fill={((motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1125,345 1125,260 935,260 935,270 1115,270 1115,345" /> 
          
          {/* Air pipe from air pump P1 to motor valve MV1 */}
          <polyline fill={((motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="865,260 795,260 795,345 805,355 805,270 865,270" /> 
          
          {/* Air pipe from motor valve 1 to outdoor air */}
          <polyline fill={(motorValve1Open && (motorValve2Closed || motorValve2Open)  && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="835,375 865,375 865,385 835,385" /> 
          <text x="870" y="385" fill={textColor} style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Outdoor air</text>

          {/* Air pipe from motor valve 1 to equilibrator */}
          <polyline fill={(motorValve1Closed && (motorValve2Closed || motorValve2Open) && airPumpP1Running)?"rgb(0,213,0)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="795,415 795,460 955,460 955,500 965,500 965,450 805,450 805,415" /> 

          {/* Water pipe from motor valve 3 to equilibrator */}
          <polyline fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="535,470 940,470 940,500 930,500 930,480 535,480 535,470" /> 

          {/* Manual ball valve on water pipe from QT1 */}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="500,470 480,460 480,490 500,480" />
          <circle cx="510" cy="475" r="10" fill={pipeIdleColor} stroke={strokeColor} strokeWidth={"1"} />
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="520,470 540,460 540,490 520,480" />
          <polyline fill="transparent" stroke={strokeColor} strokeWidth="1" points="510,465 510,450 520,450 500,450" />
          <text x="460" y="440" fill={textColor} style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Manual valve</text>

          {/* Aquasensor 1 */}
          <rect fill={aquasensor1ErrorCode > 0?"rgb(213,0,0)":pipeIdleColor} stroke={strokeColor} x="980" y="245" width="90" height="40" ry="10"/> 
          <text x="984" y="270" fill={textColor} style={{fontSize:"16",alignmentBaseline:"start",textAnchor:"start"}} >Aquasensor</text>
          <rect onClick={() => this.openAquasensorNH3Modal(this.props.unitName + "/aquasensor/aquasensor1", {"objectName":"Aquasensor1"})} fill="transparent" stroke="transparent" strokeWidth="1" x="970" y="235" width="110" height="60"/>  
        
          {/* equilibrator tank */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="910" y="500" width="100" height="400" ry="20"/> 
          <rect fill="rgb(0,100,213)" stroke={strokeColor} x="910" y="500" width="100" height="400" ry="20" clipPath={externalWaterPumpP3Running?"inset(50% 0% 0% 0%)":"inset(100% 0% 0% 0%)"} />
          
          {/* Manual ball valve on water pipe to QT1 */}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="335,580 325,560 355,560 345,580" />
          <circle cx="340" cy="585" r="10" fill={pipeIdleColor} stroke={strokeColor} strokeWidth={"1"} />
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="335,595 325,615 355,615 345,595" />
          <polyline fill="transparent" stroke={strokeColor} strokeWidth="1" points="330,585 315,585 315,595 315,575" />
          <text x="215" y="590" fill={textColor} style={{ fontSize: "16", alignmentBaseline: "start", textAnchor: "start" }} >
            Manual valve
          </text>

          {/* water pipe from P3 to Manual Valve*/}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="335,615 335,720 345,720 345,720 345,615" />
          <text x="200" y="840" fill={textColor} style={{ fontSize: "16", alignmentBaseline: "start", textAnchor: "start" }} >
            Fish tank
          </text>

          {/* water pipe from P3 to fish tank*/}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="335,780 335,830 280,830 280,840 345,840 345,780" />

          {/* equilibrator outlet tank */}
          <rect fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} x="1020" y="700" width="100" height="200" ry="20"/>

          {/* Water pipe between equilibrator tank and equilibrator outlet tank */}
          <polyline fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="950,900 950,960 1080,960 1080,900 1060,900 1060,940 970,940 970,900" />

          {/* Water pipe between equilibrator outlet tank and aquasense pump sump tank */}
          <polyline fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="1120,715 1200,715 1200,725 1120,725" />
          <text x="1210" y="725" fill={textColor} style={{ fontSize: "16", alignmentBaseline: "start", textAnchor: "start" }} >
            Outlet
          </text>

          {/* Water pipe from water pump ball valve to QT1*/}
          <polyline fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="480,470 335,470, 335,560, 345,560, 345,480, 480,480" />

          {/*Line from QT1 to water pipe*/}
          <polyline fill={(externalWaterPumpP3Running)?"rgb(0,100,213)":pipeIdleColor} stroke={strokeColor} strokeWidth="2" points="390,470 390,445" />

          {/* Air pump P1*/}
          <SvgMotor objectName="P1" objectDescription="Air pump" openModal={this.openMotorModal} x={900} y={265} dataPath={this.props.unitName + "/motor/p1"} data={data} showResetOutput={false} 
          showAutoSpeedSetpoint={false} userName={this.props.userName} idleDirection="down" runningDirection="left" hideFeedbackSpeed={true} colorTheme={this.props.colorTheme}/>

          {/* Water pump P3*/}
          <SvgMotor objectName="P3" objectDescription="Water pump" openModal={this.openMotorModal} x={340} y={750} dataPath={this.props.unitName + "/motor/p3"} data={data} showResetOutput={false} 
          showAutoSpeedSetpoint={false} userName={this.props.userName} idleDirection="left" runningDirection="up" titlePositionX="-50" titlePositionY="30" colorTheme={this.props.colorTheme}/>

          {/* Lye tank (from Chemical pump P6) */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="550" y="700" width="100" height="100" ry="10"/>
          <rect fill="rgb(160,32,240)" stroke={strokeColor} x="550" y="700" width="100" height="100" ry="10" clipPath="inset(20% 0% 0% 0%)"/>
          <text x="600" y="750" fill={textColor} style={{fontSize:"22",alignmentBaseline:"middle",textAnchor:"middle"}} >Lye tank</text>
          <polyline fill={(chemicalPumpP6Running)?"rgb(160,32,240)":pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="595,700 595,480 605,480 605,700 595,700" />
          
          {/* Lye pipe check valve*/}
          <polyline fill={pipeIdleColor} stroke={strokeColor} strokeWidth="1" points="590,540 610,540 605,520 595,520 590,540"/>
          <polyline fill="rgb(120,120,120)" stroke={strokeColor} strokeWidth="1" points="590,500 610,500 605,520 595,520 590,500"/>
          <text x="545" y="520" fill={textColor} style={{fontSize:"16",alignmentBaseline:"middle",textAnchor:"middle"}} >Check valve</text>
         
          {/* Chemical pump P6*/}
          <SvgMotor objectName="P6" objectDescription="Chemical pump" openModal={this.openMotorModal} x={600} y={600} dataPath={this.props.unitName + "/motor/p6"} data={data} showResetOutput={false} 
          showAutoSpeedSetpoint={true} userName={this.props.userName} idleDirection="left" runningDirection="up" titlePositionX="-50" titlePositionY="30" feedbackSpeedPositionX={-40} colorTheme={this.props.colorTheme}/>

          {/* Motor valve MV1*/}
          <SvgValve objectName="MV1" valve1Direction="up" valve1OpenColor="rgb(0,213,0)" valve1CloseColor="rgb(0,213,0)" 
                                     valve2Direction="down" valve2OpenColor={pipeIdleColor} valve2CloseColor="rgb(0,213,0)" 
                                     valve3Direction="right" valve3OpenColor="rgb(0,213,0)" valve3CloseColor={pipeIdleColor} 
          showAutoPositionSetpoint={false} showManualPositionSetpoint={false} titlePositionX="45" titlePositionY="-2" colorTheme={this.props.colorTheme}
          objectDescription="Air Motor valve" openModal={this.openValveModal} x={800} y={380} dataPath={this.props.unitName + "/valve/mv1"} data={data} userName={this.props.userName}/>

          {/* Motor valve MV2*/}
          <SvgValve objectName="MV2" valve1Direction="up" valve1OpenColor="rgb(0,213,0)" valve1CloseColor="rgb(0,213,0)" 
                                     valve2Direction="down" valve2OpenColor={pipeIdleColor} valve2CloseColor="rgb(0,213,0)" 
                                     valve3Direction="left" valve3OpenColor="rgb(0,213,0)" valve3CloseColor={pipeIdleColor} 
          showAutoPositionSetpoint={true} showManualPositionSetpoint={true} titlePositionX="-45" titlePositionY="-2" colorTheme={this.props.colorTheme}
          objectDescription="Air Motor valve" openModal={this.openValveModal} x={1120} y={380} dataPath={this.props.unitName + "/valve/mv2"} data={data} userName={this.props.userName}/>

          <SvgAnalog objectName="TAN" objectDescription="TAN mg/l" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={350} y={200} colorTheme={this.props.colorTheme}
           instrumentTag={"TAN"} dataPath={this.props.unitName + "/analog/tan_mgl"} data={data} userName={this.props.userName} decimals="2" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="NH3" objectDescription="NH3 ug/l" pvMin={0.0} pvMax={5000.0} openModal={this.openAnalogModal} x={350} y={300} colorTheme={this.props.colorTheme} 
           instrumentTag={"NH3"} dataPath={this.props.unitName + "/analog/nh3_ugl"} data={data} userName={this.props.userName} decimals="1" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="NH4" objectDescription="NH4 ug/l" pvMin={0.0} pvMax={5000.0} openModal={this.openAnalogModal} x={500} y={300}  colorTheme={this.props.colorTheme}
           instrumentTag={"NH4"} dataPath={this.props.unitName + "/analog/nh4_ugl"} data={data} userName={this.props.userName} decimals="1" showInstrument={false} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>

          <SvgAnalog objectName="QT2" objectDescription="pH" pvMin={0.0} pvMax={14.0} openModal={this.openAnalogModal} x={1075} y={660} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"PH"} dataPath={this.props.unitName + "/analog/qt2"} data={data} userName={this.props.userName}/>

          <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} openModal={this.openAnalogModal} x={390} y={428} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"PH"} dataPath={this.props.unitName + "/analog/qt1"} data={data} userName={this.props.userName}/>

          {/* Sensor line for pH to equilibrator outlet tank */}
          <polyline stroke={strokeColor} strokeWidth="2" points="1075,680 1075,700" />

          <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={1200} y={660} showInstrument={true} colorTheme={this.props.colorTheme}
           instrumentTag={"TT"} dataPath={this.props.unitName + "/analog/tt1"} data={data} userName={this.props.userName}/>         

          <SvgAnalog objectName="ST1" objectDescription="Salinity" pvMin={0.0} pvMax={50.0} openModal={this.openAnalogModal} x={500} y={200} showInstrument={false} colorTheme={this.props.colorTheme}
           instrumentTag={"ST"} dataPath={this.props.unitName + "/analog/st1"} data={data} userName={this.props.userName} modeSelectPosX={65} modeSelectPosY={-51} alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>         

          {/* Verification parameter box */}
          <rect fill={pipeIdleColor} stroke={strokeColor} x="100" y="1000" width="100" height="50" ry="10"/>
          <text x="150" y="1025" fill={textColor} style={{fontSize:"16",alignmentBaseline:"middle",textAnchor:"middle"}} >Verification</text>
          <rect onClick={() => this.openAquasenseVerificationModal(this.props.unitName + "/verification", {"objectName":"Verification","objectDescription":"Verification","userName":this.props.userName}) } fill="transparent" stroke="transparent" x="100" y="1000" width="100" height="50" ry="10"/>

          </svg>
        </div>
 
      </div>
    )

    }

}