import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import SvgAnalog from '../svg/SvgAnalog';

export default class AquasenseOverView extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false
      }
    }

  jsonGet(jsondata, key){
      try{
        return jsondata[key];
      }catch(error){
        //console.error(error);
        return {};
      }
    }

  getCurrentTime(){
    let dateObj = new Date();
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }


  render() {
    let aqs0690ComError = this.props.listComErrorSystem.includes("aqs-06-90");
    let aqs0691ComError = this.props.listComErrorSystem.includes("aqs-06-91");
    let aqs0692ComError = this.props.listComErrorSystem.includes("aqs-06-92");
    let aqs0693ComError = this.props.listComErrorSystem.includes("aqs-06-93");
    let aqs0694ComError = this.props.listComErrorSystem.includes("aqs-06-94");
    let aqs0695ComError = this.props.listComErrorSystem.includes("aqs-06-95");
    let aqs0696ComError = this.props.listComErrorSystem.includes("aqs-06-96");

    let aqs0690redCrossWhenComError = <></>;
    if(aqs0690ComError){
      aqs0690redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M120 310 L505 560 M120 560 L505 310"/>
    }
    let aqs0691redCrossWhenComError = <></>;
    if(aqs0691ComError){
      aqs0691redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M550 310 L935 560 M550 560 L935 310"/>
    }
    let aqs0692redCrossWhenComError = <></>;
    if(aqs0692ComError){
      aqs0692redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M980 310 L1365 560 M980 560 L1365 310"/>
    }
    let aqs0693redCrossWhenComError = <></>;
    if(aqs0693ComError){
      aqs0693redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    }
    let aqs0694redCrossWhenComError = <></>;
    if(aqs0694ComError){
      aqs0694redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    }
    let aqs0695redCrossWhenComError = <></>;
    if(aqs0695ComError){
      aqs0695redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    }
    let aqs0696redCrossWhenComError = <></>;
    /*
    if(aqs0696ComError){
      aqs0696redCrossWhenComError = <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d="M1410 310 L1795 560 M1410 560 L1795 310"/>
    }
    */
    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";

    let row = 0;
    let col = 0;
    let aqs_overview_width = 385;
    let aqs_overview_hight = 440; //440;
    let aqs_overview_margin_x = 55;
    let aqs_overview_margin_y = 100;
    let x_shift = aqs_overview_width + aqs_overview_margin_x;
    let y_shift = aqs_overview_hight + aqs_overview_margin_y;

    return(
      <div style={{height:"100%", width:"100%",backgroundColor:backgroundColor}}>
        <div style={{width:"100%", height:"100%"}}>
         {/*<svg  style={{ width:"100%",height:"100%"}} viewBox="0 0 1920 1200" xmlns="http://www.w3.org/2000/svg">*/}
         {/*<svg  style={{ width:"100%",height:"100%"}} viewBox={`0 0 ${window.screen.width} ${window.screen.height}`} xmlns="http://www.w3.org/2000/svg">*/}
         <svg  style={{ width:"100%",height:"100%"}} viewBox={`0 0 1920 1400`} xmlns="http://www.w3.org/2000/svg">
            <text x="960" y="50" fill={textColor} style={{fontSize:"35",alignmentBaseline:"middle",textAnchor:"middle"}} >Overview</text>
            <text x="1900" y="100" fill={textColor} style={{fontSize:"25",alignmentBaseline:"end",textAnchor:"end"}} >{this.getCurrentTime()}</text>

            {row = 0}
            {col = 0}

            {/* Border for aqs-06-92 */}
            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 1</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-92</text>
            {/*<rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height="380" ry="20"/>*/}
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-92/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0692redCrossWhenComError*/}
            {(aqs0692ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* aqs-06-92 */}

            {row = 0}
            {col = 1}

            {/* Border for AQS-06-96 Air*/}
            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 1</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-96</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 kg/h" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-96/analog/o2_kgh"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 kg/h" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-96/analog/co2_kgh"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S mg/h" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-96/analog/h2s_mgh"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="FT1" objectDescription="Air velocity" pvMin={0.0} pvMax={14.0} x={(245 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-96/analog/air_speed"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Air temperature" pvMin={0.0} pvMax={50.0} x={(370 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-96/analog/air_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            {/*aqs0696redCrossWhenComError*/}
            {(aqs0696ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* AQS-06-96 */}

            {row = 0}
            {col = 2}
            {/* Border for AQS-06-95 */}
            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 2</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-95</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
             <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
             <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-95/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0695redCrossWhenComError*/}
             {(aqs0695ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* AQS-06-95 */}

            {row = 0}
            {col = 3}

            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 3</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-91</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-91/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0691redCrossWhenComError*/}
            {(aqs0691ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* aqs-06-91 */}

            {row = 1}
            {col = 0}

            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 4</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-90</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-90/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0690redCrossWhenComError*/}
            {(aqs0690ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* aqs-06-90 */}            

            {row = 1}
            {col = 1}

            {/* Border for AQS-06-94 */}
            {/*<text x="312.5" y="200" fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS</text>*/}
            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 5</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-94</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-94/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0694redCrossWhenComError*/}
            {(aqs0694ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* AQS-06-94 */} 

            {row = 1}
            {col = 2}

            {/* Border for AQS-06-93 */}
            <text x={(312.5 + col * x_shift).toString()} y={(200 + row * y_shift).toString()} fill={textColor} style={{fontSize:"36",alignmentBaseline:"middle",textAnchor:"middle"}} >RAS 6</text>
            <text x={(312.5 + col * x_shift).toString()} y={(245 + row * y_shift).toString()} fill={textColor} style={{fontSize:"24",alignmentBaseline:"middle",textAnchor:"middle"}} >AQS-06-93</text>
            <rect fill="transparent" stroke="rgb(150,150,150)" x={(120.5 + col * x_shift).toString()} y={(260 + row * y_shift).toString()} width={aqs_overview_width} height={aqs_overview_hight.toString()} ry="20"/>
            <SvgAnalog objectName="O2" objectDescription="O2 %sat" pvMin={0.0} pvMax={150.0} x={(185 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/o2_sat"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="CO2" objectDescription="CO2 mg/l" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/co2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="H2S" objectDescription="H2S ug/l" pvMin={0.0} pvMax={50.0} x={(435 + col * x_shift)} y={(390 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/h2s_ugl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="O2" objectDescription="O2 mg/l" pvMin={0.0} pvMax={50.0} x={(185 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/o2_mgl"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ALK" objectDescription="ALK ug/l" pvMin={0.0} pvMax={500.0} x={(310 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/alkalinity_mgl"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TURB" objectDescription="TURB NTU" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(500 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/optional_sensor4"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"NTU"}/>
            <SvgAnalog objectName="QT1" objectDescription="pH" pvMin={0.0} pvMax={14.0} x={(185 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/ph"} data={this.props.data} userName={this.props.userName} decimals="2" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="TT1" objectDescription="Water temperature" pvMin={0.0} pvMax={50.0} x={(310 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/water_temperature"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>
            <SvgAnalog objectName="ST1" objectDescription="salinity" pvMin={0.0} pvMax={35.0} x={(435 + col * x_shift)} y={(600 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/salinity"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"/>  
            <SvgAnalog objectName="TGP" objectDescription="TGP %" pvMin={0.0} pvMax={35.0} x={(310 + col * x_shift)} y={(700 + row * y_shift)} showInstrument={false} colorTheme={this.props.colorTheme}
             dataPath={"aqs-06-93/analog/optional_sensor5"} data={this.props.data} userName={this.props.userName} decimals="1" alarmTextPosX={0} alarmTextPosY={-15} alarmTextAlignmentBaseline="middle"
             unit_of_measurement={"%"}/>
            {/*aqs0693redCrossWhenComError*/}
            {(aqs0693ComError)?
                <path fill="transparent" stroke="rgb(213,0,0)" strokeWidth="3" d={`M${120  + col * x_shift} ${260  + row * y_shift + 60} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 300} M${120  + col * x_shift} ${260 + row * y_shift + 300} L${120  + col * x_shift+aqs_overview_width} ${260 + row * y_shift + 60}`}/>:
                <></>
            }
            {/* AQS-06-93 */}

          </svg>
        </div>
 
      </div>
    )

    }

}
