import React from "react";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {getServerUrl} from '../ApplicationNetworking'

export default class AlarmWindow extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loading:false,
        error:false,
        filter:""
      }
    }

  componentDidMount() {
      //
    }

  handleFilterChange = (event) => {
    this.setState({filter:event.target.value});
  }

  handleAckAlarm = (systemName, alarmLabel, alarmName) => {
    this.ackAlarm(systemName, alarmLabel, alarmName);
  }

  ackAlarm = (systemName, alarmLabel, alarmName) => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false});
     let url = getServerUrl() + "ackalarm?auth_token=" + this.props.auth_token + "&system_name=" + systemName + "&alarm_label=" + alarmLabel + "&alarm_name=" + alarmName + "&username=" + this.props.userName;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         //pass
        this.props.updateAlarms();
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  ackAllAlarms = () => {
    if(this.props.auth_token){
     this.setState({ isLoading:true, error:false});
     let url = getServerUrl() + "ackalarmall?auth_token=" + this.props.auth_token + "&username=" + this.props.userName;
     fetch(url)
       .then((response) => {
         if(!response.ok) throw new Error(response);
         else return response.json();
       })
       .then((data) => {
         //pass
        this.props.updateAlarms();
       })
       .catch((error) => {
         this.setState({ isLoading: false, error:true  });
       });
    }
  }

  formatDate = (dateObj) => {
    let month = (dateObj.getMonth() + 1);
    if(month < 10){
      month = "0" + month;
    }
    let date = dateObj.getDate();
    if(date < 10){
      date = "0" + date;
    }
    let hours = dateObj.getHours();
    if(hours < 10){
      hours = "0" + hours;
    }
    let minutes = dateObj.getMinutes();
    if(minutes < 10){
      minutes = "0" + minutes;
    }
    let seconds = dateObj.getSeconds();
    if(seconds < 10){
      seconds = "0" + seconds;
    }
    return dateObj.getFullYear() + "-" + month + "-" + date + " " + hours + ":" + minutes + ":" + seconds;
  }


  render() {

    let backgroundColor = this.props.colorTheme() === "light" ? "#f2f2f2" : "#323232";
    let textColor = this.props.colorTheme() === "light" ? "black" : "rgb(200,200,200)";
    let sidebarItemsBorderColor = this.props.colorTheme() === "light" ? "rgb(160,160,160)" : "rgb(200,200,200)";

    let alarms = {};
    try{
      alarms = JSON.parse(this.props.alarms);
    }
    catch(error){
      // pass
    }

    let alarmListDiv = [];

    let alarmList = [];

    let numberofActiveAlarmsUnAcked = 0;
    let numberofActiveAlarmsAcked = 0;
    let numberOfInactiveAlarmsUnAcked = 0;

    let activeNotAcknowledgedText = "Active, not acknowledged";
    let activeAcknowledgedText = "Active, acknowledged";
    let inactiveNotAcknowledgedText = "Inactive, not acknowledged";


    Object.keys(alarms).forEach(systemName => {
        Object.keys(alarms[systemName]).forEach(alarmLabel => {
          Object.keys(alarms[systemName][alarmLabel]).forEach(alarmName => {
            let alarmPriority = alarms[systemName][alarmLabel][alarmName].alarm_priority;
            let alarmActive = alarms[systemName][alarmLabel][alarmName].active;
            let alarmAcked = alarms[systemName][alarmLabel][alarmName].acked;
            let alarmTimeActiveUTC = alarms[systemName][alarmLabel][alarmName].time_active_utc;
            let alarmTimeActive = "";
            if(alarmTimeActiveUTC){ // Convert UTC to local time for time alarm active
              alarmTimeActive = this.formatDate(new Date(alarmTimeActiveUTC + " UTC"));
            }
            let alarmTimeInactiveUTC = alarms[systemName][alarmLabel][alarmName].time_inactive_utc;
            let alarmTimeInactive = "";
            if(alarmTimeInactiveUTC){ // Convert UTC to local time for time alarm inactive
               alarmTimeInactive = this.formatDate(new Date(alarmTimeInactiveUTC + " UTC"));
            }
            let alarmAckedByUser = alarms[systemName][alarmLabel][alarmName].acked_by_user;
            let alarmAckedTimeUTC = alarms[systemName][alarmLabel][alarmName].acked_time_utc;
            let alarmAckedTime = "";
            if(alarmAckedTimeUTC){ // Convert UTC to local time for time alarm ack.
               alarmAckedTime = this.formatDate(new Date(alarmAckedTimeUTC + " UTC"));
            }
            if(alarmActive !== undefined){
              let state = "";
              if(alarmActive && !alarmAcked){
                state = activeNotAcknowledgedText;
                numberofActiveAlarmsUnAcked += 1; 
              }
              else if(alarmActive && alarmAcked){
                state = activeAcknowledgedText;
                numberofActiveAlarmsAcked += 1;
              }
              else if(!alarmActive && !alarmAcked){
                state = inactiveNotAcknowledgedText;
                numberOfInactiveAlarmsUnAcked += 1;
              }
              else{
                // Alarm should be deleted when the alarm is inactive
                state = "Unknown"
              }
              alarmList.push({
                "alarmTimeActive":alarmTimeActive,
                "alarmTimeInactive":alarmTimeInactive,
                "alarmState":state,
                "systemName":systemName,
                "alarmLabel":alarmLabel,
                "alarmName":alarmName,
                "alarmPriority":alarmPriority,
                "alarmAckedByUser":alarmAckedByUser,
                "alarmAckedTime":alarmAckedTime,
                "alarmAcked":alarmAcked
              })
            }

          })
        })
      });


    /* Sort the unorganized alarms list based on active time stamp. The newest times should be first in list. */ 
    let sortedAlarmTimeActive = alarmList.sort(function (a, b) {
      return new Date(b.alarmTimeActive) - new Date(a.alarmTimeActive);
    });
    
    //console.log(alarmList);

    alarmListDiv = sortedAlarmTimeActive.map((jsonData) => {
      let alarmState = jsonData.alarmState;
      let alarmPriority = jsonData.alarmPriority;
      let alarmAcked = jsonData.alarmAcked;
      let systemName = jsonData.systemName;
      let alarmLabel = jsonData.alarmLabel;
      let alarmName = jsonData.alarmName;
      let alarmTextColor = alarmAcked ? "#ffffff" : "black";
      let backgroundColorStyle={backgroundColor:"white"};
      if(alarmState === activeNotAcknowledgedText){
        if(alarmPriority === "Critical"){
          backgroundColorStyle = {backgroundColor:"#ff0f0f", color:"#ffffff"} // rgb(255,15,15)
        }
        else if(alarmPriority === "High"){
          backgroundColorStyle = {backgroundColor:"#ff4545", color:"#ffffff"} // rgb(255,69,69)
        }
        else if(alarmPriority === "Medium"){
          backgroundColorStyle = {backgroundColor:"#ffff00", color:"#ffffff"} // rgb(255,255,0)
        }
        else{
          backgroundColorStyle = {backgroundColor:"#ffc2c2", color:"#ffffff"} // rgb(255,194,194)
        }
      }
      else if(alarmState === activeAcknowledgedText){
        if(alarmPriority === "Critical"){
          backgroundColorStyle = {backgroundColor:"#000000", color:"#ffffff"} // rgb(0,0,0)
        }
        else if(alarmPriority === "High"){
          backgroundColorStyle = {backgroundColor:"#363636", color:"#ffffff"} // rgb(54,54,54)
        }
        else if(alarmPriority === "Medium"){
          backgroundColorStyle = {backgroundColor:"#6c6c6c", color:"#ffffff"} // rgb(108,108,108)
        }
        else{
          backgroundColorStyle = {backgroundColor:"#a2a2a2", color:"#ffffff"} // rgb(162,162,162)
        }
      }
      else{
        if(alarmPriority === "Critical"){
          backgroundColorStyle = {backgroundColor:"#0f0fff", color:"#ffffff"} // rgb(15,15,255)
        }
        else if(alarmPriority === "High"){
          backgroundColorStyle = {backgroundColor:"#4545ff", color:"#ffffff"} // rgb(69,69,255)
        }
        else if(alarmPriority === "Medium"){
          backgroundColorStyle = {backgroundColor:"#8383ff", color:"#ffffff"} // rgb(131,131,255)
        }
        else{
          backgroundColorStyle = {backgroundColor:"#c2c2ff", color:"#ffffff",hoverColor:"#ffffff"} // rgb(194,194,255)
        }
      }
      // Hide ack button if alarm is already acked or the user does not have privilege to ack alarms (operator and administrators).
      let alarmAckBtnHidden = true;
      if(this.props.userRole !== undefined){
        let role = this.props.userRole.toLowerCase();
        if(role === "operator" || role === "administrator"){
          if(!alarmAcked){
            alarmAckBtnHidden = false;
          }
        }
      } 
      let uniqueKey = jsonData.systemName + jsonData.alarmLabel + jsonData.alarmName;
      let object = <tr style={backgroundColorStyle} key={uniqueKey}>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{jsonData.alarmTimeActive}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{alarmState}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{systemName}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{alarmLabel}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{alarmName}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{alarmPriority}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{jsonData.alarmTimeInactive}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{jsonData.alarmAckedByUser}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">{jsonData.alarmAckedTime}</td>
                    <td style={{color:alarmTextColor}} id="alarmTableTd">
                      <div className="text-center">
                        <button onClick={() => this.handleAckAlarm(systemName, alarmLabel, alarmName)} type="button" className={alarmAckBtnHidden ? "btn btn-outline-dark btn-sm invisible" : "btn btn-outline-dark btn-sm visible"}>
                        Ack.</button>

                      </div>
                    </td>
                    </tr>
      return object;
    });

    let totalNumberOfAlarms = numberofActiveAlarmsUnAcked + numberofActiveAlarmsAcked;
    let ackAllButton = <></>;
    let role = this.props.userRole.toLowerCase();
    if((role === "operator" || role === "administrator") && (numberOfInactiveAlarmsUnAcked > 0 || numberofActiveAlarmsUnAcked > 0)){
      ackAllButton = <button style={{backgroundColor:backgroundColor, color:textColor, borderColor:sidebarItemsBorderColor, fontWeight:this.state.showPage === "overview" ? "bold" : "normal"}} onClick={() => this.ackAllAlarms()} type="button">Ack. all</button>
    }
    
    return(
      <div style={{width:"inherit",height:"inherit", backgroundColor: backgroundColor}}>

 
        <div className="container-fluid">

          <div className="row">
            <div className="col-12 text-center">
                <h3 style={{color:textColor}}> Alarms </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-start">
                <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Number of active alarms, unacknowledged: {numberofActiveAlarmsUnAcked}</p>
            </div>
            <div className="col-4 text-center">
                <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Number of active alarms, acknowledged: {numberofActiveAlarmsAcked}</p>
            </div>
            <div className="col-4 text-end">
                <p style={{fontSize:"calc(50% + 0.4vw)", color:textColor}}> Total alarms: {totalNumberOfAlarms}</p>
            </div>
          </div>

          <div className="row mb-1">
              <div className="col-12">
                {ackAllButton}
              </div>
          </div>

          
          <div className="table-responsive" style={{maxHeight:"70vh", border:"3px solid #787878", borderRadius:"5px"}}>
            <table style={{maxHeight:"50px"}} className="table table-bordered table-hover table-condensed">
              <thead style={{position:"sticky", "top":0, backgroundColor:backgroundColor}}>
                <tr>
                  <th style={{color:textColor}} scope="col">Active time</th>
                  <th style={{color:textColor}} scope="col">State</th>
                  <th style={{color:textColor}} scope="col">System name</th>
                  <th style={{color:textColor}} scope="col">Label</th>
                  <th style={{color:textColor}} scope="col">Name</th>
                  <th style={{color:textColor}} scope="col">Priority</th>
                  <th style={{color:textColor}} scope="col">Inactive time</th>
                  <th style={{color:textColor}} scope="col">Ack. by</th>
                  <th style={{color:textColor}} scope="col">Ack. time</th>
                  <th style={{color:textColor}} scope="col">Ack.</th>
                </tr>
              </thead>
              <tbody>

              {alarmListDiv}

              </tbody>
            </table>
          </div>

        </div> {/* container-fluid */}
      </div>
    )
  }

}
